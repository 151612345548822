/*
 Copyright 2024, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setSnackBarMessage } from '../actions/interactions';
import userManager from '../utils/userManager';
import { getAddDevicesToken } from '../utils/Cachengo';

export default function AddDevicesToken(props) {
    const dispatch = useDispatch();

    const { show, setTokenReady, setAddDevicesToken, org } = props;

    useEffect(() => {
        if (show) {
          userManager.signinSilent()
            .then((user) => {
              getAddDevicesToken(user.access_token, org)
                .then(res => {
                  setAddDevicesToken(res.token);
                  setTokenReady(true);
                }).catch(err => {
                  dispatch(setSnackBarMessage('Error generating token', 'error'));
                })
            }).catch(err => {
              dispatch(setSnackBarMessage('Error refreshing access token', 'error'));
            })
        }
        return () => {
            setTokenReady(false);
        };
    }, [dispatch, show, setTokenReady, setAddDevicesToken, org]);

    return null;
}
