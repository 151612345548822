import * as React from "react";
import { useState, useRef, useEffect } from "react";

import PropTypes from "prop-types";
import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import CardHeader from "@material-ui/core/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

import { connect } from "react-redux";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import { blue } from "@material-ui/core/colors";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { fetchClusters, getDevicesByProduct } from "../utils/Cachengo";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getOrgKeys } from "../utils/Cachengo";

import RentANodeDashboardCustomizationModal from "./modals/RentANodeDashboardCustomizationModal";

import { removeEventHandler, addEventHandler } from "../actions/items";

import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const mapStateToProps = (state) => {
  return {
    payInfo: state.portal.payInfo || {},
  };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  resources: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    height: "100%",
    width: "100%",
    margin: 0,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  link: {
    padding: theme.spacing(2),
    // textAlign: 'left',
    color: blue[700],
    "&:hover": {
      color: blue[900],
    },
    fontFamily: "Helvetica",
    justifyContent: "space-between",
    width: "100%",
  },
  card: {
    border: "1px solid #d5dbdb",
    // maxHeight: '4vh'
    // borderRadius: '1px',
    // borderWeight: 'light',
  },
  divLeft: {
    display: "inline",
    float: "left",
  },
  divRight: {
    display: "inline",
    float: "right",
    justifyContent: "right",
  },
  header: {
    textAlign: "center",
    justifyContent: "center",
    // padding: theme.spacing(2),
    width: "100%",
    textAlign: "left",
    background: "#eaeded",
    color: "black",
  },
  title: {
    textAlign: "center",
    justifyContent: "center",
    // padding: theme.spacing(2),
    width: "100%",
    textAlign: "left",
    // background: '#fb5000',
    color: "#fb5000",
  },
  bread: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  gridItem: {
    height: "100%",
    width: "100%",
    padding: 0,
    margin: 0,
    boxSizing: "border-box",
    "& > *": {
      height: "100%",
      width: "100%",
    },
  },
}));

// variable to hold the responsive grid component
const ResponsiveGridLayout = WidthProvider(Responsive);

function handleCrumbClick(event) {
  event.preventDefault();
}

function ConnectedRentDashboard(props) {
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('calories');
  // const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(true);
  // const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [instances, setInstances] = useState(null);
  const [userDevices, setUserDevices] = useState(null);
  const [keys, setKeys] = useState([]);
  const [apps, setApps] = useState([]);
  const [cluster, setClusters] = useState(null);

  // state for the modal to select the cards on the dashboard
  const [modalOpen, setModalOpen] = useState(false);

  // state for the available cards to add to the dashboard
  const [availableItems, setAvailableItems] = useState([
    { id: 0, name: "Resources" },
    { id: 1, name: "Launch Instance" },
    // { id: 2, name: "Create a Cluster" },
  ]);

  // dimensions for the different layouts
  const [layouts, setLayouts] = useState({
    lg: [
      { i: "resources", x: 0, y: 0, w: 12, h: 2 },
      { i: "launchInstance", x: 0, y: 1, w: 6, h: 2 },
      { i: "createACluster", x: 6, y: 1, w: 6, h: 2 },
    ],
    md: [
      { i: "resources", x: 0, y: 0, w: 12, h: 3 },
      { i: "launchInstance", x: 0, y: 3, w: 6, h: 2 },
      { i: "createACluster", x: 6, y: 3, w: 6, h: 2 },
    ],
    sm: [
      { i: "resources", x: 0, y: 0, w: 12, h: 3 },
      { i: "launchInstance", x: 0, y: 3, w: 12, h: 2 },
      { i: "createACluster", x: 0, y: 5, w: 12, h: 2 },
    ],
    xs: [
      { i: "resources", x: 0, y: 0, w: 12, h: 3 },
      { i: "launchInstance", x: 0, y: 3, w: 12, h: 2 },
      { i: "createACluster", x: 0, y: 5, w: 12, h: 2 },
    ],
    xxs: [
      { i: "resources", x: 0, y: 0, w: 12, h: 4 },
      { i: "launchInstance", x: 0, y: 4, w: 12, h: 3 },
      { i: "createACluster", x: 0, y: 7, w: 12, h: 3 },
    ],
  });

  // state for the current items
  const [items, setItems] = useState(availableItems);

  // row height for the responsiveGridLayout
  const rowHeight = 150;

  // default widths and height for the grid
  const DEFAULT_CARD_WIDTH = 6;
  const DEFAULT_CARD_HEIGHT = 2;

  // state for the currently selected cards on the dashboard
  const [selectedCards, setSelectedCards] = useState(
    availableItems.map((item) => item.name)
  );

  // handler for opening the modal
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  // handler to close the modal
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // calculates the size of the componenets when an item is added or removed from the screen
  const handleApplyChanges = (newSelectedCards) => {
    const generateLayoutForBreakpoint = (breakpoint) => {
      let y = 0;
      return selectedCards.map((card, index) => {
        const id = card.toLowerCase().replace(" ", "");
        const isFullWidth =
          breakpoint === "sm" || breakpoint === "xs" || breakpoint === "xxs";
        let itemLayout;

        if (id === "resources") {
          itemLayout = {
            i: id,
            x: 0,
            y: 0,
            w: 12,
            h:
              breakpoint === "lg"
                ? 2
                : breakpoint === "md"
                ? 2
                : breakpoint === "sm" || breakpoint === "xs"
                ? 2
                : 3, // for xxs
          };
          y = itemLayout.h;
        } else {
          const w = isFullWidth ? 12 : DEFAULT_CARD_WIDTH;
          const h =
            breakpoint === "xxs"
              ? 3
              : breakpoint === "xs"
              ? 3
              : breakpoint === "sm"
              ? 4
              : 2;
          itemLayout = {
            i: id,
            w: w,
            h: h,
            x: isFullWidth ? 0 : ((index - 1) % 2) * DEFAULT_CARD_WIDTH,
            y: y,
          };
          y += h;
        }
        return itemLayout;
      });
    };

    // new layouts generated after the change has been made
    const newLayouts = {
      lg: generateLayoutForBreakpoint("lg"),
      md: generateLayoutForBreakpoint("md"),
      sm: generateLayoutForBreakpoint("sm"),
      xs: generateLayoutForBreakpoint("xs"),
      xxs: generateLayoutForBreakpoint("xxs"),
    };

    // set the new layouts in the state
    setLayouts(newLayouts);
    // set the selected cards in the state
    setSelectedCards(newSelectedCards);
    handleModalClose();
  };

  const classes = useStyles();
  const history = props.history;
  // const history = props.history
  const goToMyRentals = () => {
    history.push("/my_rentals");
  };
  const goToMyClusters = () => {
    history.push("/my_clusters");
  };
  const goToMyKeys = () => {
    history.push("/my_keys");
  };
  const goToUserDevices = () => {
    history.push("/user_devices");
  };

  useEffect(() => {
    getDevicesByProduct(null).then((res) => {
      if (res && res.success) {
        setInstances(
          res.devices
            .filter((device) => device.is_vm === true)
            .filter(
              (device) =>
                device.status !== "terminating" &&
                device.status !== "terminated"
            )
        );
        setUserDevices(res.devices.filter((device) => device.is_vm === false));
        fetchClusters().then((res) => {
          if (res && res.success && res.clusters.length > 0) {
            setClusters(res.clusters);
          } else {
            setClusters([]);
          }
        });
        // setInstances(testData.filter(device => device.is_vm === true))
      } else {
        setInstances([]);
        setClusters([]);
      }
    });
    getOrgs();
  }, []);

  const getOrgs = () => {
    getOrgKeys().then((res) => {
      if (res && res.success) {
        setKeys(res.public_keys);
      } else {
        setKeys([]);
      }
    });
  };

  // calculates the layout of the screen when the component renders
  // and watches for changes to selectedCards and available items to rerender
  useEffect(() => {
    const newItems = availableItems.filter((item) =>
      selectedCards.includes(item.name)
    );
    setItems(newItems);

    // calculates the layouts based on the current breakpoint (screen size)
    const generateLayoutForBreakpoint = (breakpoint) => {
      let y = 0;
      return selectedCards.map((card, index) => {
        const id = card.toLowerCase().replace(" ", "");
        const isFullWidth =
          breakpoint === "sm" || breakpoint === "xs" || breakpoint === "xxs";
        let itemLayout;

        if (id === "resources") {
          itemLayout = {
            i: id,
            x: 0,
            y: 0,
            w: 12,
            h:
              breakpoint === "lg"
                ? 2
                : breakpoint === "md"
                ? 2
                : breakpoint === "sm" || breakpoint === "xs"
                ? 2
                : 3, // for xxs
          };
          y = itemLayout.h;
        } else {
          const w = isFullWidth ? 12 : DEFAULT_CARD_WIDTH;
          const h =
            breakpoint === "xxs"
              ? 3
              : breakpoint === "xs"
              ? 3
              : breakpoint === "sm"
              ? 4
              : 2;
          itemLayout = {
            i: id,
            w: w,
            h: h,
            x: isFullWidth ? 0 : ((index - 1) % 2) * DEFAULT_CARD_WIDTH,
            y: y,
          };
          y += h;
        }
        return itemLayout;
      });
    };

    const newLayouts = {
      lg: generateLayoutForBreakpoint("lg"),
      md: generateLayoutForBreakpoint("md"),
      sm: generateLayoutForBreakpoint("sm"),
      xs: generateLayoutForBreakpoint("xs"),
      xxs: generateLayoutForBreakpoint("xxs"),
    };

    setLayouts(newLayouts);
  }, [selectedCards, availableItems]);

  // method to render the cards on the dashboard
  const renderCard = (item) => {
    switch (item.name) {
      case "Resources":
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Paper className={classes.resources}>
              <Grid container spacing={1}>
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  xs={12}
                  title="Resources"
                  className={classes.header}></CardHeader>
                <Grid item xs={12} sm={4}>
                  <Card className={classes.card}>
                    <Link
                      className={classes.link}
                      component="button"
                      variant="body2"
                      onClick={goToMyRentals}>
                      <div className={classes.divLeft}>Instances (Running)</div>
                      <div className={classes.divRight}>
                        {instances === null ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          instances.filter(
                            (device) => device.is_connected === true
                          ).length
                        )}
                      </div>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className={classes.card}>
                    <Link
                      className={classes.link}
                      component="button"
                      variant="body2"
                      onClick={goToMyRentals}>
                      <div className={classes.divLeft}>Instances</div>
                      <div className={classes.divRight}>
                        {instances === null ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          instances.length
                        )}
                      </div>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className={classes.card}>
                    <Link
                      className={classes.link}
                      component="button"
                      variant="body2"
                      onClick={goToMyClusters}>
                      <div className={classes.divLeft}>Clusters</div>
                      <div className={classes.divRight}>
                        {cluster === null ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          cluster.length
                        )}
                      </div>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className={classes.card}>
                    <Link
                      className={classes.link}
                      component="button"
                      variant="body2"
                      onClick={goToMyKeys}>
                      <div className={classes.divLeft}>Public Keys</div>
                      <div className={classes.divRight}>
                        {instances === null ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          keys.length
                        )}
                      </div>
                    </Link>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className={classes.card}>
                    <Link
                      className={classes.link}
                      component="button"
                      variant="body2"
                      onClick={goToUserDevices}>
                      <div className={classes.divLeft}>User Devices</div>
                      <div className={classes.divRight}>
                        {userDevices === null ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          userDevices.length
                        )}
                      </div>
                    </Link>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      case "Launch Instance":
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Paper className={classes.resources}>
              <Grid container spacing={1}>
                <CardHeader
                  titleTypographyProps={{ variant: "h6" }}
                  xs={12}
                  title="Launch Instance"
                  subheader="To get started, launch a Rent-a-Node instance, which is a virtual server in the cloud."
                  className={classes.header}></CardHeader>
                <Grid item xs={12}>
                  <h4
                    style={{
                      height: "fit-content",
                      padding: 0,
                      margin: 0,
                      marginTop: 0,
                    }}>
                    Launch a new Rent-a-Node instance.
                  </h4>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{
                      height: "fit-content",
                      width: "240px",
                      backgroundColor: "#fc5001",
                      fontWeight: "bold",
                      color: "white",
                    }}
                    // color="primary"
                    // color='#fc5001'
                    variant="contained"
                    // theme={theme}
                    onClick={() => history.push("/new_instance")}>
                    Launch an Instance
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <h4
                    style={{
                      height: "fit-content",
                      padding: 0,
                      margin: 0,
                      marginTop: 0,
                    }}>
                    Manage a new Rent-a-Node instance.
                  </h4>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{
                      height: "fit-content",
                      backgroundColor: "#ff9900",
                      fontWeight: "bold",
                      width: "240px",
                    }}
                    // color="primary"
                    // color='#fc5001'
                    variant="contained"
                    // theme={theme}
                    onClick={goToMyRentals}>
                    Manage Instance
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      // case "Create a Cluster":
      //   return (
      //     <div style={{ width: "100%", height: "100%" }}>
      //       <Paper className={classes.resources}>
      //         <Grid container spacing={1}>
      //           <CardHeader
      //             titleTypographyProps={{ variant: "h6" }}
      //             xs={12}
      //             title="Create a Cluster"
      //             subheader="Launch a new cluster using kubernetes or manage existing clusters."
      //             className={classes.header}></CardHeader>
      //           {/* <Grid item xs={12}>
      //             <h4
      //               style={{
      //                 height: "fit-content",
      //                 padding: 0,
      //                 margin: 0,
      //                 marginTop: 0,
      //               }}>
      //               Create a new Kubernetes cluster using available instances.
      //             </h4>
      //           </Grid> */}
      //           {/* <Grid item xs={12}>
      //             <Button
      //               style={{
      //                 height: "fit-content",
      //                 width: "240px",
      //                 backgroundColor: "#fc5001",
      //                 fontWeight: "bold",
      //                 color: "white",
      //               }}
      //               // color="primary"
      //               // color='#fc5001'
      //               variant="contained"
      //               // theme={theme}
      //             >
      //               Create a Cluster
      //             </Button>
      //           </Grid> */}
      //           <Grid item xs={12}>
      //             <h4
      //               style={{
      //                 height: "fit-content",
      //                 padding: 0,
      //                 margin: 0,
      //                 marginTop: 0,
      //               }}>
      //               Manage cluster deployments.
      //             </h4>
      //           </Grid>
      //           <Grid item xs={12}>
      //             <Button
      //               style={{
      //                 height: "fit-content",
      //                 width: "240px",
      //                 backgroundColor: "#ff9900",
      //                 fontWeight: "bold",
      //               }}
      //               // color="primary"
      //               // color='#fc5001'
      //               variant="contained"
      //               // theme={theme}
      //               // onClick={goToMyClusters}
      //             >
      //               Manage Cluster
      //             </Button>
      //           </Grid>
      //         </Grid>
      //       </Paper>
      //     </div>
      //   );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.bread}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb">
              <Link
                color="inherit"
                component="button"
                variant="body2"
                onClick={() => {
                  props.history.push("/");
                }}>
                Home
              </Link>
              {/* <Link color="inherit" href="/getting-started/installation/" onClick={handleCrumbClick}>
          Core
        </Link> */}
              <Typography color="textPrimary">Rent-a-Node</Typography>
            </Breadcrumbs>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <CardHeader
              titleTypographyProps={{ variant: "h4" }}
              xs={12}
              title="Welcome to Rent-a-Node!"
              className={classes.title}></CardHeader>
            {/* <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleModalOpen}
            >
              Edit
            </Button> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            rowHeight={125}
            margin={[10, 10]}
            containerPadding={[10, 10]}
            onLayoutChange={(currentLayout, allLayouts) => {
              setLayouts(allLayouts);
            }}
            minW={6}
            minH={2}
            draggableCancel=".MuiButton-root,.MuiLink-root">
            {items.map((item) => (
              <div
                key={item.name.toLowerCase().replace(" ", "")}
                className={classes.gridItem}>
                {renderCard(item)}
              </div>
            ))}
          </ResponsiveGridLayout>
        </Grid>
      </Grid>
      <RentANodeDashboardCustomizationModal
        show={modalOpen}
        handleClose={handleModalClose}
        availableCards={availableItems.map((item) => item.name)}
        selectedCards={selectedCards}
        onApplyChanges={handleApplyChanges}
      />
    </div>
  );
}

const RentDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedRentDashboard);

export default RentDashboard;
