/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { Component } from 'react';
 import {
     Avatar,
     Box,
     Button,
     Dialog,
     DialogActions,
     DialogContent,
     DialogTitle,
     Link,
     List, 
     ListItem, 
     ListItemAvatar,
     ListItemSecondaryAction,
     ListItemText,
     TextField,
     Typography,
     withStyles,
     Tabs,
     Tab,
     Paper,
   } from '@material-ui/core';
 import TollIcon from '@material-ui/icons/Toll';
 import { TabPanel, TabContext } from '@material-ui/lab';

 import CopyToClipBoard from './CopyToClipBoard';
 import AddDevicesToken from './AddDevicesToken';

 export default class CLIModal extends Component {
     constructor(props) {
         super(props);
 
         // initialize the state with the props passed 
         this.state = {
             show: this.props.show,
             // initial title index selected
             selectedTitle: '0',
             isTokenReady: false,
             addDevicesToken: "",
         };
 
         // bind the handle close method
         this.handleClose = this.handleClose.bind(this);
         this.handleTitleClick = this.handleTitleClick.bind(this);
         this.setTokenReady = this.setTokenReady.bind(this);
         this.setAddDevicesToken = this.setAddDevicesToken.bind(this);
     }
 
     
 
     // method to call the close
     handleClose() {
         this.props.handleClose();
     }
 
     // checks the OS of the user when the component mounts and shows the installation
     // instructions for that OS
     componentDidMount() {
         const os = this.detectOS();
         this.setState({ selectedTitle: os.toString() });
     }
 
     // handles the clicks on the title and selects the new index
     handleTitleClick(event, newValue) {
         this.setState({ selectedTitle: newValue.toString()});
     }

     setTokenReady(ready) {
         this.setState({ isTokenReady: ready });
     }

     setAddDevicesToken(token) {
         this.setState({ addDevicesToken: token });
     }

     // detects the OS of the user
     detectOS = () => {
         const platform = window.navigator.platform;
         const userAgent = navigator.userAgent.toLowerCase();
 
         if (platform.toLowerCase().includes('win')) {
             return 2;
         } else if (platform.toLowerCase().includes('mac')) {
             return 1;
         } else if (platform.toLowerCase().includes('linux')) {
             return 0;
         } else {
             // default to linux
             return 0;
         }
     }
 
     render() {
         const { selectedTitle, addDevicesToken, isTokenReady } = this.state;
         const { showToken } = this.props;
         const titles = ['Linux', 'Mac', 'Windows'];
 
         return (
             <Dialog 
               open={this.props.show}
               onClose={this.props.handleClose}
               fullWidth
               maxWidth='md'
             >
                 <AddDevicesToken
                   show={this.props.show && showToken}
                   setTokenReady={this.setTokenReady}
                   setAddDevicesToken={this.setAddDevicesToken}
                 />
                 <DialogTitle id='dialog-title'>Add a User Device Quickly With the Following Steps</DialogTitle>
                 <DialogContent>
                     {/* <Typography variant='body1'>
                         Download and install the CLI quickly with the following steps
                     </Typography> */}
                     <Typography variant='body1'>
                         Download and run our installer to get started
                     </Typography>
                     {/* <Box mt={2}>
                         <Typography variant='body2' component='div'>
                             <ul>
                                 <li>Here's what you need to get started with your Cachengo product.</li>
                             </ul>
                         </Typography>
                     </Box> */}
                     <br />
                     { showToken &&
                     <div>
                         <Typography variant='body1'>
                             For advanced users: this is the installation token to use in your environment.
                         </Typography>
                         <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar style={{backgroundColor: '#FC5001' }}>
                                <TollIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                              <TextField
                                label='Token'
                                value={isTokenReady ? addDevicesToken : 'Generating...'}
                                margin='dense'
                                type='text'
                                variant='outlined'
                                fullWidth
                                color='secondary'
                                disabled
                                inputProps={{ style:{ fontFamily: "monospace" } }}
                              />
                            </ListItemText>
                            <ListItemSecondaryAction>
                              {isTokenReady && <CopyToClipBoard item={addDevicesToken} />}
                            </ListItemSecondaryAction>
                          </ListItem>
                         </List>
                     </div>
                     }
                     <Box mt={2} mb={2}>
                         <Button 
                           variant='contained' 
                           color='secondary'
                           component='a'
                           href='https://downloads.cachengo.com/cachengo-cli/cachengo-cli_linux_x86_64'
                           target='_blank'
                           rel='noopener noreferrer'
                         >
                             Download
                         </Button>
                     </Box>
                     <Paper>
                         <TabContext value={selectedTitle}>
                             <Tabs
                               value={selectedTitle}
                               onChange={this.handleTitleClick}
                               indicatorColor='secondary'
                               textColor='secondary'
                               centered
                             >
                                 {titles.map((title, idx) => (
                                     <Tab label={title} value={idx.toString()} key={idx} />
                                 ))}
                             </Tabs>
                             {/* Instructions for the Linux Installation */}
                             <TabPanel value="0">
                                 <Typography variant='body1'>
                                     Linux installation instructions
                                     <br />
                                     <br />
                                     1. Download cachengo-cli_linux_x86_64
                                     <br />
                                     <br />
                                     2. Run “sudo mv cachengo-cli_linux_x86_64 cachengo-cli && sudo chmod +x cachengo-cli”
                                     <br />
                                     <br />
                                     3. Move cachengo-cli_linux_x86_64 to “sudo mv cachengo-cli /usr/bin”
                                     <br />
                                     <br />
                                     4. Run “sudo cachengo-cli deviceInit -c cachengo.com
                                     <br />
                                     <br />
                                     5. Run “sudo cachengo-cli deviceInit -c cachengo.com
                                     <br />
                                     <br />
                                     Use the following credentials when prompted:
                                 </Typography>
                                 <Box ml={2}>
                                     <List>
                                         <ListItem>
                                             <ListItemText primary='organization – your organization handle (the organization handle can be found under the “My Organization” tab in your organization)' />
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='username – your username'/>
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='password - your password'/>
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='name - Use whatever you would like your machine to be called in Portal e.g. BradsPC'/>
                                         </ListItem>
                                     </List>
                                 </Box>
                                 <Typography>
                                     6. Find user device under single devices
                                 </Typography>
                             </TabPanel>
                             {/* Instructions for the Mac Installation */}
                             <TabPanel value="1">
                                 <Typography variant='body1'>
                                     Mac installation instructions
                                     <br />
                                     <br />
                                     1. Download Cachengo-Installer-production.dmg
                                     <br />
                                     <br />
                                     2. Run Cachengo-Installer-production.dmg
                                     <br />
                                 </Typography>
                                 <Box ml={2}>
                                     <List>
                                         <ListItem>
                                             <ListItemText primary='organization – your organization handle (the organization handle can be found under the “My Organization” tab in your organization)' />
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='username – your username'/>
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='password - your password'/>
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='name - Use whatever you would like your machine to be called in Portal e.g. BradsPC'/>
                                         </ListItem>
                                     </List>
                                 </Box>
                                 <Typography>
                                     3. Find user device under single devices
                                 </Typography>
                             </TabPanel>
                             {/* Instructions for the Windows Installation */}
                             <TabPanel value="2">
                                 <Typography variant='body1'>
                                     Windows installation instructions
                                     <br />
                                     <br />
                                     1. Download Cachengo-Installer-production.exe
                                     <br />
                                     <br />
                                     2. Run Cachengo-Installer-production.exe
                                     <br />
                                 </Typography>
                                 <Box ml={2}>
                                     <List>
                                         <ListItem>
                                             <ListItemText primary='organization – your organization handle (the organization handle can be found under the “My Organization” tab in your organization)' />
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='username – your username'/>
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='password - your password'/>
                                         </ListItem>
                                         <ListItem>
                                             <ListItemText primary='name - Use whatever you would like your machine to be called in Portal e.g. BradsPC'/>
                                         </ListItem>
                                     </List>
                                 </Box>
                                 <Typography>
                                     3. Find user device under single devices
                                 </Typography>
                             </TabPanel>
                         </TabContext>
                     </Paper>
                     <Box textAlign='center' mt={2}>
                         <Button onClick={this.handleClose} variant='contained' color='secondary'>Done</Button>
                     </Box>
                 </DialogContent>
             </Dialog>
         );
     }
 }
